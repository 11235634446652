import React, { useEffect } from "react";
import { CarbonConnect, AutoSyncedSourceTypes } from "carbon-connect";
import axios from "axios";
import { useLocation } from "react-router-dom";
import './Custom.css'; // Add this line to import the CSS

const Carbon = () => {
    // Get the customer_id from the URL query parameter
    const search = useLocation().search;
    const id = new URLSearchParams(search).get("customer_id");
    const askai_id = new URLSearchParams(search).get("askai_id");
    var entry_Point = new URLSearchParams(search).get("entryPoint");
    const v2 = new URLSearchParams(search).get("v2");
    const v2_tickets = new URLSearchParams(search).get("v2_tickets");
    const knowledge_type = new URLSearchParams(search).get("knowledge_type");

    const redirect_url = new URLSearchParams(search).get("redirect_url");
    // console.log(id, askai_id, entry_Point, redirect_url, v2);
    var enabledIntegrations = [];
    if (v2 === "yes") {
        enabledIntegrations = [{
                id: "NOTION",
                chunkSize: 400,
                overlapSize: 20,
                showFilesTab: true
            },
            {
                id: "GOOGLE_DRIVE",
                chunkSize: 400,
                overlapSize: 20,
                showFilesTab: true,
                fileSyncConfig: {
                    split_rows: true
                }
            },
            {
                id: "INTERCOM",
                chunkSize: 400,
                overlapSize: 20,
                showFilesTab: true
            },
            {
                id: "DROPBOX",
                chunkSize: 400,
                overlapSize: 20,
                useCarbonFilePicker: true,
                showFilesTab: true
            },
            {
                id: "ONEDRIVE",
                chunkSize: 400,
                overlapSize: 20,
                useCarbonFilePicker: true,
                showFilesTab: true
            },
            {
                id: "ZENDESK",
                chunkSize: 400,
                overlapSize: 20,
                showFilesTab: true
            },
            {
                id: "FRESHDESK",
                chunkSize: 400,
                overlapSize: 20,
                showFilesTab: true
            },
            {
                id: "CONFLUENCE",
                chunkSize: 400,
                overlapSize: 20,
                useCarbonFilePicker: true,
                showFilesTab: true,
                syncFilesOnConnection: false
            },
            {
                id: "SHAREPOINT",
                chunkSize: 400,
                overlapSize: 20,
                useCarbonFilePicker: true,
                showFilesTab: true
            },
            {
                id: "SALESFORCE",
                chunkSize: 400,
                overlapSize: 20,
                useCarbonFilePicker: true,
                showFilesTab: true,
                syncFilesOnConnection: false
            }
        ];
        if (entry_Point === "LOCAL_FILES") {
            enabledIntegrations = [{
                id: "LOCAL_FILES",
                chunkSize: 400,
                overlapSize: 20,
                maxFileSize: 30000000,
                allowMultipleFiles: true,
                showFilesTab: false,
                split_rows: true,
                maxFilesCount: 25,
                filePickerMode: 'BOTH',
                allowedFileTypes: [{
                        extension: 'csv'
                    },
                    {
                        extension: 'xlsx'
                    },
                    {
                        extension: 'mp4',
                    },
                    {
                        extension: 'mp3',
                    },
                    {
                        extension: 'txt'
                    },
                    {
                        extension: 'pdf',
                    },
                    {
                        extension: 'json'
                    },
                    {
                        extension: 'docx',
                    },
                    {
                        extension: 'pptx',
                    },
                    {
                        extension: 'md',
                    }
                ]
            }, ];
        }
        if (v2_tickets === "yes") {
            enabledIntegrations = [{
                    id: "INTERCOM",
                    chunkSize: 400,
                    overlapSize: 20,
                    showFilesTab: false,
                    fileSyncConfig: {
                        auto_synced_source_types: [AutoSyncedSourceTypes.CONVERSATION]
                    }
                },
                {
                    id: "ZENDESK",
                    chunkSize: 400,
                    overlapSize: 20,
                    showFilesTab: false,
                    fileSyncConfig: {
                        auto_synced_source_types: [AutoSyncedSourceTypes.TICKET]
                    }
                }
            ];
        }
        // Start of Selection
        if (knowledge_type === "help_centre") {
            enabledIntegrations = [{
                    id: "INTERCOM",
                    chunkSize: 400,
                    overlapSize: 20,
                    showFilesTab: false
                },
                {
                    id: "FRESHDESK",
                    chunkSize: 400,
                    overlapSize: 20,
                    showFilesTab: false
                },
                {
                    id: "GITBOOK",
                    chunkSize: 400,
                    overlapSize: 20,
                    showFilesTab: true
                },
                {
                    id: "ZENDESK",
                    chunkSize: 400,
                    overlapSize: 20,
                    showFilesTab: false
                }
            ];
        }
        if (knowledge_type === "internal") {
            enabledIntegrations = [{
                    id: "NOTION",
                    chunkSize: 400,
                    overlapSize: 20,
                    showFilesTab: true
                },
                {
                    id: "GOOGLE_DRIVE",
                    chunkSize: 400,
                    overlapSize: 20,
                    showFilesTab: true,
                    fileSyncConfig: {
                        split_rows: true
                    }
                },
                {
                    id: "DROPBOX",
                    chunkSize: 400,
                    overlapSize: 20,
                    useCarbonFilePicker: true,
                    showFilesTab: true
                },
                {
                    id: "ONEDRIVE",
                    chunkSize: 400,
                    overlapSize: 20,
                    useCarbonFilePicker: true,
                    showFilesTab: true
                },
                {
                    id: "CONFLUENCE",
                    chunkSize: 400,
                    overlapSize: 20,
                    useCarbonFilePicker: true,
                    showFilesTab: true,
                    syncFilesOnConnection: false
                },
                {
                    id: "SHAREPOINT",
                    chunkSize: 400,
                    overlapSize: 20,
                    useCarbonFilePicker: true,
                    showFilesTab: true
                },
                {
                    id: "SALESFORCE",
                    chunkSize: 400,
                    overlapSize: 20,
                    useCarbonFilePicker: true,
                    showFilesTab: true,
                    syncFilesOnConnection: false
                }
            ];
        }
    } else {
        enabledIntegrations = [{
                id: "NOTION",
                chunkSize: 1500,
                overlapSize: 20,
                skipEmbeddingGeneration: true,
                enableAutoSync: true
            },
            {
                id: "GOOGLE_DRIVE",
                chunkSize: 1500,
                overlapSize: 20,
                skipEmbeddingGeneration: true,
                enableAutoSync: true
            },
        ];
    }
    entry_Point = entry_Point || "INTEGRATION_LIST";
    // console.log("Entry point: " + entry_Point);

    // State to hold the results
    // const [results, setResults] = useState(null);
    const handleOnSuccess = (data) => {
        console.log("Result of OnSuccess=>", data);
        if (redirect_url != null) {
            window.location.assign(
                `${redirect_url}?source=${entry_Point}&status=success`
            );
        }
    };

    // Function to fetch Carbon access token
    const tokenFetcher = async() => {
        const response = await axios.get(
            `${process.env.REACT_APP_BASE_DEV}auth/fetch-carbon-token`, {
                params: { customer_id: id }, //customer_id from URL param
                headers: {
                    Authorization: process.env.REACT_APP_AUTHORIZATION_TOKEN,
                },
            }
        );

        // Return the access_token from the response data
        return response.data;
    };

    // Alert if customer_id is not provided
    useEffect(() => {
        if (id == null) {
            if (askai_id == null) {
                alert("Please provide Customer ID and askai ID in the URL");
            } else {
                alert("Please provide customer ID in the url");
            }
        } else if (askai_id == null) {
            alert("Please provide askai id in the url");
        }
    }, []);
    // useEffect(() => {
    //     if (v2 === "yes") {
    //         const applyStyles = () => {
    //             let element = document.querySelector("body > div.cc-bg-blackA9");
    //             if (element) {
    //                 let carbonelement = document.querySelector('body > div[role="dialog"]');
    //                 if (carbonelement) {
    //                     carbonelement.style.width = '95%';
    //                 }
    //                 element.classList.remove("cc-bg-blackA9");
    //                 element.classList.add("bg-white");
    //             }

    //             let ccModal = document.querySelector('.cc-modal');
    //             if (ccModal) {
    //                 ccModal.style.width = '95%';
    //                 ccModal.style.border = 'none';
    //                 ccModal.style.outline = 'none';
    //                 let firstChild = ccModal.firstElementChild;
    //                 if (firstChild) {
    //                     firstChild.style.width = '100%';
    //                 }
    //             }

    //             // // Find and hide the "HIDE ME" button
    //             // let hideButton = document.querySelector('button:contains("HIDE ME")');
    //             // if (hideButton) {
    //             //     hideButton.style.display = 'none';
    //             // }
    //         };

    //         // Apply styles immediately
    //         applyStyles();

    //         // Set up a MutationObserver to watch for changes in the DOM
    //         const observer = new MutationObserver(applyStyles);
    //         observer.observe(document.body, { childList: true, subtree: true });

    //         // Clean up the observer when the component unmounts
    //         return () => observer.disconnect();
    //     }
    // }, [v2]);
    return ( <
        >
        {
            (askai_id != null) & (id != null) ? (
                v2 == "yes" ? ( <
                    CarbonConnect orgName = "My AskAI"
                    brandIcon = "https://7ac07acbae116a5155080d295197d8d6.cdn.bubble.io/f1689768781903x860453087150673900/Logo.png"
                    tokenFetcher = { tokenFetcher }
                    tags = {
                        v2 == "yes" ? {
                            askai_id: askai_id,
                            version: "v2",
                        } : {
                            askai_id: askai_id,
                        }
                    }
                    embeddingModel = "OPENAI_ADA_LARGE_1024"
                    maxFileSize = { 10000000 }
                    backButtonText = ""
                    theme = "light"
                    splitRows = { true }
                    showFilesTab = { false }
                    sendDeletionWebhooks = { true }
                    enabledIntegrations = { enabledIntegrations }
                    allowMultipleFiles = { true }
                    onError = {
                        (error) => console.log("Data on Error: ", error)
                    }
                    primaryBackgroundColor = "#1F3261"
                    primaryTextColor = "#FFFFFF"
                    entryPoint = { entry_Point }
                    secondaryBackgroundColor = "#FFFFFF"
                    secondaryTextColor = "#1F3261"
                    open = { true }
                    alwaysOpen = { true }
                    tosURL = "https://myaskai.com/terms"
                    privacyPolicyURL = "https://myaskai.com/privacy" >
                    <
                    >
                    <
                    /> < /
                    CarbonConnect >
                ) : ( <
                    CarbonConnect orgName = "My AskAI"
                    brandIcon = "https://7ac07acbae116a5155080d295197d8d6.cdn.bubble.io/f1689768781903x860453087150673900/Logo.png"
                    tokenFetcher = { tokenFetcher }
                    useCarbonFilePicker = { false }
                    tags = {
                        v2 == "yes" ? {
                            askai_id: askai_id,
                            version: "v2",
                        } : {
                            askai_id: askai_id,
                        }
                    }
                    maxFileSize = { 10000000 }
                    sendDeletionWebhooks = { true }
                    theme = "light"
                    enabledIntegrations = { enabledIntegrations }
                    entryPoint = { entry_Point }
                    navigateBackURL = { `${redirect_url}?source=${entry_Point}&status=cancelled` }
                    onError = {
                        (error) => console.log("Data on Error: ", error)
                    }
                    primaryBackgroundColor = "#1F3261"
                    primaryTextColor = "#FFFFFF"
                    secondaryBackgroundColor = "#FFFFFF"
                    secondaryTextColor = "#1F3261"
                    open = { true }
                    alwaysOpen = { true }
                    tosURL = "https://myaskai.com/terms"
                    privacyPolicyURL = "https://myaskai.com/privacy" >
                    <
                    >
                    <
                    /> < /
                    CarbonConnect >
                )
            ) : (
                ""
            )
        } {
            /* {results && (
                    <div className="flex flex-col w-full absolute items-center h-full top-[50rem] mt-2">
                      {results?.data_source_external_id && (
                        <div className="flex ">
                          <div>data_source_external_id: </div>
                          <div className="ml-2">`{results?.data_source_external_id}`</div>
                        </div>
                      )}
                      {results?.sync_status && (
                        <div className="flex ">
                          <div>sync_status: </div>
                          <div className="ml-2">`{results?.sync_status}`</div>
                        </div>
                      )}
                      {results?.objects && (
                        <div className="flex ">
                          <div>objects: </div>
                          <div className="ml-2">`{results?.objects}`</div>
                        </div>
                      )}
                      {results?.tags && (
                        <div className="flex ">
                          <div>tags: </div>
                          <div className="ml-2">`{results?.tags}`</div>
                        </div>
                      )}
                    </div>
                  )} */
        } <
        />
    );
};

export default Carbon;